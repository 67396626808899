import globe from './globe.svg';
import logo from './logo.svg';
import vn from './vietnam.png';
import uk from './united-states.png';
import { Row, Col, List, Space } from 'antd';
import './App.css';
import { createRef, useEffect, useRef, useState } from 'react';
import { Carousel } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import clock from './clock.svg';
function App() {
  const [lang, setLang] = useState('en');
  const [timeCount, setTimeCount] = useState({
    days: '', hours: '', minutes: '', seconds: ''
  });

  const languages = {
    en: {
      h1: `"BRING BUSINESS TO METAVERSE"`,
      h2: "SUPPORT PROGRAM FROM BIZVERSE",
      h3: "300 BUSINESSES IN REAL-ESTATE",
      h4: "500 SHOWROOMS",
      h5: "100 EDUCATION UNITS",
      h6: "with pricing discounted to zero",
      h7: "Total support: USD 1.5M",
      title: "SUPPORT PROGRAM FROM BIZVERSE",
      start: "START DAY",
      end: "END DAY",
      button: "REGISTER",
      day: "DAYS",
      hour: "HOURS",
      min: "MINUTES",
      second: "SECONDS",
      contact: "For more information, please contact: ",
      slogan: "Hurry up! The offering package is up to $1.5M for the very first 900 enterprises to register",
      subtitle: {
        title_1: "FOR BUSINESSES IN REAL ESTATE",
        title_2: "FOR SHOWROOM",
        title_3: "FOR EDUCATIONAL INSTITUTIONS "
      },
      card: {
        edu_1: "School VR360 Tour",
        edu_2: "School  360 Space",
        edu_3: "Bizverse 3D Space Meeting Room",
        room_1: "Showroom model on Bizverse World",
        room_2: "Showroom VR360 Tour",
        room_3: "Bizverse 3D Space Meeting Room",
        estate_1: "Real estate project model on Bizverse World",
        estate_2: "Project overview",
        estate_3: "Sample apartment ",
        estate_4: "Bizverse 3D Space Meeting Room"
      },
      link: {
        edu_1: "https://vr360.com.vn/projects/neu/",
        edu_2: "https://bizverse.world/ductc1#17.95/16.073027/108.202146/0/73",
        edu_3: "https://my360bizverse.world/ToALwsy/small-meeting-room",
        room_1: "https://bizverse.world/guest#19.96/16.0482501/108.2116069/-123.7/81",
        room_2: "https://bizverse.world/ad#19.97/16.0481924/108.2115104/-121.1/77",
        room_3: "https://my360bizverse.world/ToALwsy/small-meeting-room",
        estate_1: "https://bizverse.world/guest#17.09/15.917086/108.326553/-158/62 ",
        estate_2: "https://vr360.com.vn/projects/peakhomes/",
        estate_3: "https://tour.bizverse.world/tour/6nature",
        estate_4: "https://my360bizverse.world/ToALwsy/small-meeting-room"
      },
      education: [
        `Bringing models of schools and educational institutions to Bizverse World`,
        `Creating a VR360 school tour with 1-3 drone overview views, 10-15 360-degree ground images at notable points, including basic information about the school, and a virtual MC introducing the school`,
        `Use the Bizverse Meeting tool for free indefinitely`,
        `In Bizverse 3D Space, you can utilize immersive interactive meeting room, classroom space tools with a 3D avatar for free`,
        `Banner videos/images displayed in prominent spots in Bizverse World`,
        `Free vrStore booth on vrMall/vrExpo of Bizverse World`,
        `Creating a free business information page on Bizverse Social`,
        `Freely promote the school to the Bizverse World international community`,
        `Over 10,000 online courses in various fields`,
        `Upload information on the school to Bizverse's E-class system for free`,

      ],
      estate: [
        "Bringing the project's model to Bizverse World",
        "Creating a VR360 project tour including 1-3 drone-aerial pictures of the project overview and 1 sample apartment (no more than 100m2) utilizing 3D Scanning technologies, integrating fundamental project information",
        "Use the Bizverse Meeting tool for free indefinitely",
        "In Bizverse 3D Space, you can utilize immersive interactive meeting room space tools with a 3D avatar for free",
        "Banner videos/images displayed in prominent spots in Bizverse World",
        "Free vrStore booth on vrMall/vrExpo of Bizverse World",
        "Creating a free business information page on Bizverse Social",
        "Freely promote your company to the Bizverse World international community",
        "Upload information on real estate projects to Bizverse's E-Realestate system for free.",

      ],
      showroom: [
        "Bringing showroom to Bizverse World",
        "Creating a VR360 project tour for showroom by 3D Scanning technologies (no more than 200m2); integrating fundamental project information",
        "Use the Bizverse Meeting tool for free indefinitely",
        "In Bizverse 3D Space, you can utilize immersive interactive meeting room space tools with a 3D avatar for free",
        "Banner videos/images displayed in prominent spots in Bizverse World",
        "Free vrStore booth on vrMall/vrExpo of Bizverse World",
        "Creating a free business information page on Bizverse Social",
        "Freely promote your company to the Bizverse World international community",
        "Upload information on the showroom to Bizverse's E-commerce system for free",

      ]
    },
    vi: {
      h1: `"MANG DOANH NGHIỆP LÊN METAVERSE"`,
      h2: "CHƯƠNG TRÌNH HỖ TRỢ TỪ BIZVERSE",
      h3: "300 DOANH NGHIỆP BẤT ĐỘNG SẢN ",
      h4: "500 GIAN HÀNG TRƯNG BÀY ",
      h5: "100 ĐƠN VỊ GIÁO DỤC",
      h6: "VỚI GIÁ ƯU ĐÃI 0 ĐỒNG",
      h7: "Gói hỗ trợ: 1.5 Triệu USD",
      start: "NGÀY BẮT ĐẦU",
      end: "NGÀY KẾT THÚC",
      day: "NGÀY",
      hour: "GIỜ",
      min: "PHÚT",
      second: "GIÂY",
      title: "CHƯƠNG TRÌNH HỖ TRỢ DOANH NGHIỆP",
      slogan: "Nhanh tay đăng ký! Gói hỗ trợ lên đến 1,5 triệu USD chỉ dành cho 900 đơn vị doanh nghiệp đăng ký sớm nhất",
      subtitle: {
        title_1: "dành cho các dự án bất động sản",
        title_2: "dành cho các showroom",
        title_3: "dành cho các cơ sở giáo dục"
      },
      card: {
        edu_1: "Trường học VR360 Tour",
        edu_2: "Trường học 360 Space",
        edu_3: "Phòng họp Bizverse 3D Space",
        room_1: "Mô hình showroom trên Bizverse World",
        room_2: "Showroom VR360 Tour",
        room_3: "Phòng họp Bizverse 3D Space",
        estate_1: "Mô hình dự án trên Bizverse World",
        estate_2: "Tổng quan dự án",
        estate_3: "Căn hộ mẫu",
        estate_4: "Phòng họp Bizverse 3D Space"
      },
      link: {
        edu_1: "https://vr360.com.vn/projects/neu/",
        edu_2: "https://bizverse.world/ductc1#17.95/16.073027/108.202146/0/73",
        edu_3: "https://my360bizverse.world/ToALwsy/small-meeting-room",
        room_1: "https://bizverse.world/guest#19.96/16.0482501/108.2116069/-123.7/81",
        room_2: "https://bizverse.world/ad#19.97/16.0481924/108.2115104/-121.1/77",
        room_3: "https://my360bizverse.world/ToALwsy/small-meeting-room",
        estate_1: "https://bizverse.world/guest#17.09/15.917086/108.326553/-158/62 ",
        estate_2: "https://vr360.com.vn/projects/peakhomes/",
        estate_3: "https://tour.bizverse.world/tour/6nature",
        estate_4: "https://my360bizverse.world/ToALwsy/small-meeting-room"
      },
      education: [
        `Đưa mô hình của trường, cơ sở giáo dục lên Bizverse World`,
        "Tạo VR360 tour đơn vị với 1-3 góc nhìn tổng quan bằng flycam, 10-15 góc nhìn 360 dưới đất tại các vị trí nổi bật bao gồm các thông tin cơ bản của trường, MC ảo giới thiệu về trường",
        "Sử dụng miễn phí công cụ Bizverse Meeting không giới hạn ",
        "Sử dụng miễn phí công cụ không gian phòng họp tương tác nhập vai với 3D avartar trong Bizverse 3D Space",
        "Video/hình ảnh banner xuất hiện tại các vị trí nổi bật trên Bizverse",
        "Gian hàng vrStore miễn phí trên vrMall/vrExpo của Bizverse World",
        "Tạo miễn phí trang thông tin doanh nghiệp trên Bizverse Social",
        "Quảng bá miễn phí doanh nghiệp tới cộng đồng quốc tế của Bizverse",
        "Hơn 10.000 khóa học trực tuyến về tất cả lĩnh vực",
        "Đăng tải miễn phí thông tin trường lên hệ thống E-class"
      ],
      estate: [
        "Đưa mô hình của dự án lên Bizverse World",
        "Tạo VR360 tour dự án với 1-3 góc nhìn tổng quan dự án bằng flycam và 1 căn hộ mẫu (không quá 100m2) bằng công nghệ 3D Scanning, bao gồm các thông tin cơ bản của dự án.",
        "Sử dụng miễn phí công cụ Bizverse Meeting không giới hạn",
        "Sử dụng miễn phí công cụ không gian phòng họp tương tác nhập vai với 3D avartar trong Bizverse 3D Space",
        "Video/hình ảnh banner xuất hiện tại các vị trí nổi bật trên Bizverse",
        "Gian hàng vrStore miễn phí trên vrMall/vrExpo của Bizverse World",
        "Tạo miễn phí trang thông tin doanh nghiệp trên Bizverse Social",
        "Quảng bá miễn phí doanh nghiệp tới cộng đồng quốc tế của Bizverse",
        "Đăng tải miễn phí thông tin dự án BĐS lên hệ thống E –Realestate của Bizverse"
      ],
      showroom: [
        "Đưa mô hình của Showroom lên Bizverse World",
        "Tạo VR360 tour cho showroom bằng công nghệ 3D scanning (không quá 200m2), bao gồm các thông tin cơ bản của showroom ",
        "Sử dụng miễn phí công cụ Bizverse Meeting không giới hạn",
        "Sử dụng miễn phí công cụ không gian phòng họp tương tác nhập vai với 3D avartar trong Bizverse 3D Space",
        "Video/hình ảnh banner xuất hiện trên các vị trí nổi bật Bizverse",
        "Gian hàng vrStore miễn phí trên vrMall/vrExpo của Bizverse World",
        "Tạo miễn phí trang thông tin doanh nghiệp trên Bizverse Social",
        "Quảng bá miễn phí doanh nghiệp tới cộng đồng quốc tế của Bizverse World",
        "Đăng tải miễn phí thông tin showroom lên hệ thống E-commerce"
      ],
      button: "ĐĂNG KÝ",
      contact: "Để biết thêm thông tin, vui lòng liên hệ: "

    }
  };


  const selectedElements = [
    "Item One",
    "Item Two",
    "Item Three",
    "Item Four",

  ];


  const scrollRefs = useRef([]);

  // Populate scrollable refs, only create them once
  // if the selectedElements array length is expected to change there is a workaround
  scrollRefs.current = [...Array(selectedElements.length).keys()].map(
    (_, i) => scrollRefs.current[i] ?? createRef()
  );

  // Curried handler to take index and return click handler
  const scrollSmoothHandler = (index) => {
    console.log(index, "DSD");
    var reveals = scrollRefs.current;
    reveals[index].current.scrollIntoView({ behavior: "smooth" });
  };
  const [slide, setSlide] = useState(0);

  // useEffect(() => {
  //   window.addEventListener('scroll', reveal);
  //   return () => window.removeEventListener('scroll', reveal);
  // }, []);
  // const reveal = () => {

  //   // Populate scrollable refs, only create them once
  //   // if the selectedElements array length is expected to change there is a workaround
  //   const reveals = scrollRefs.current;
  //   if (reveals[slide].current?.getBoundingClientRect().top / slide < 300) {
  //     if (slide < 3) {
  //       scrollSmoothHandler(slide + 1);;
  //       setSlide(slide + 1);
  //     } else {
  //       scrollSmoothHandler(0); setSlide(0);

  //     }
  //   }


  //   // var reveals = scrollRefs.current;
  //   // const position_0 = 
  //   // const position_1 = reveals[1].current?.getBoundingClientRect().top;
  //   // const position_2 = reveals[2].current?.getBoundingClientRect().top;
  //   // const position_3 = reveals[3].current?.getBoundingClientRect().top;
  //   // if (position_0 < 0 && slide < 1) { console.log(slide); scrollSmoothHandler(slide + 1); setSlide(slide + 1); };
  //   // if (position_1 < 0 && slide < 2) { console.log(slide); scrollSmoothHandler(slide + 1); setSlide(slide + 1); };
  //   // if (position_2 < 0 && slide < 3) { console.log(slide); scrollSmoothHandler(slide + 1); setSlide(slide + 1); };
  //   // if (position_3 < 0 && slide < 4) { setSlide(0); scrollSmoothHandler(0); };

  // };


  useEffect(() => {
    const interval = setInterval(() => {
      const timeTillDate = "06 30 2022, 00:00 am";
      const timeFormat = "MM DD YYYY, hh:mm a";
      const then = moment(timeTillDate, timeFormat).unix();
      const now = moment().unix();
      const countdown = then - now;

      const days = Math.floor(countdown / (60 * 60 * 24));
      const hours = Math.floor((countdown % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((countdown % (60 * 60)) / (60));
      const seconds = Math.floor((countdown % (60)));

      setTimeCount({ days, hours, minutes, seconds });
    }, 1000);
    return () => clearInterval(interval);
  }, []);


  return (
    <div className="App">
      <Row justify="center" style={{
        padding: '2em 0'
      }} ref={scrollRefs.current[1]} className="overview-bg" >
        <Col xl={18} lg={18} md={18} sm={22}>
          <Row>
            <div className='logo' >
              <div className='select-lg'>
                <select className='lang-select' name="lang" value={lang} onChange={(e) => setLang(e.target.value)}>
                  <option value="vi"> Vietnamese &nbsp;</option>
                  <option value="en"> English &nbsp;</option>
                </select>
              </div>
              <img src={globe} className="App-logo" alt="globe" />
              <img src={logo} alt="logo" style={{
                width: '187px',
                height: '50px',
                margin: '0px auto',
                marginTop: '0'
              }} />
            </div>

            <div className='content' ref={scrollRefs.current[0]}>
              <h1 className="pinky">{languages[lang].h1}</h1>
              <span className='register'>{languages[lang].slogan}</span>
              {/* <div style={{
                display: 'flex',
                justifyContent: "space-between",
                margin: '0 auto'
              }}>

                <div className='count-down'>
                  <img src={clock} className="count-down-logo" alt="globe" />
                  <div className='count-down-text'>
                    <span>{timeCount.days}</span>
                    <span className='text-day'>{languages[lang].day}</span>
                  </div>
                </div>
                <div className='count-down'>
                  <img src={clock} className="count-down-logo" alt="globe" />
                  <div className='count-down-text'>
                    <span>{timeCount.hours}</span>
                    <span className='text-day'>{languages[lang].hour}</span>
                  </div>
                </div>
                <div className='count-down'>
                  <img src={clock} className="count-down-logo" alt="globe" />
                  <div className='count-down-text'>
                    <span>{timeCount.minutes}</span>
                    <span className='text-day'>{languages[lang].min}</span>
                  </div>
                </div>
                <div className='count-down'>
                  <img src={clock} className="count-down-logo" alt="globe" />
                  <div className='count-down-text'>
                    <span>{timeCount.seconds}</span>
                    <span className='text-day'>{languages[lang].second}</span>
                  </div>
                </div>
              </div> */}
              {/* <Space style={{ justifyContent: "center" }}>
          <div className='datetime'>
            <span>{languages[lang].start}</span>
            <span>12.03.2022</span>
          </div>
          <div className='datetime'>
            <span>{languages[lang].end}</span>
            <span>30.06.2022</span>
          </div>
        </Space> */}
              <a href='https://forms.gle/djtP8pEZobqFT27i9' target="_blank" className='register-button' rel="noreferrer">
                <span className='register' >{languages[lang].button}</span>
              </a>

            </div>
          </Row>
        </Col>
      </Row>

      <Row justify="center" style={{
        padding: '2em 0'
      }} ref={scrollRefs.current[1]} className="estate-bg" >
        <Col span={20}>
          <Row style={{
            width: '100%',
            marginBottom: '1em'
          }}>
            <h1 className="title">{languages[lang].title}</h1>
            <h1 className="sub-title">{languages[lang].subtitle.title_1}</h1>
          </Row>
          <Row gutter={32}>
            <Col xl={12} lg={12} md={12} sm={12}>
              <List
                style={{
                  textAlign: 'initial'
                }}
                size="small"
                dataSource={languages[lang].estate}
                renderItem={item => <Space
                  style={{
                    margin: '0.2em 0',
                    width: "100%",
                    alignItems: "baseline"

                  }}
                >
                  <img src='./tick.svg' alt='tick' />
                  <span >{item}</span>
                </Space>}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12}>
              <div className='border-img'> <img src='./overview_estate.png' alt='bizverse' style={{
                width: '100%'
              }} /></div>
            </Col>
          </Row>
          <Row>
            <a href='https://forms.gle/djtP8pEZobqFT27i9' target="_blank" className='register-button' rel="noreferrer" style={{
              marginBottom: 0
            }}>
              <span className='register' >{languages[lang].button}</span>
            </a>
          </Row>
          <Row>
            <span className='line-left'></span>
            <span className='demo'>DEMO</span>
            <span className='line-right'></span>
          </Row>
          <Row gutter={24} className="desktop">
            <Col className="gutter-row" xl={6} lg={6} md={6} sm={24}>
              <a className='img-demo' href={languages[lang]?.link?.estate_1} target="_blank" rel="noreferrer">
                <img src='./estate_1.png' alt='bizverse' style={{
                  width: '100%'
                }} />
                <span>{languages[lang]?.card.estate_1}</span>
              </a>
            </Col>
            <Col className="gutter-row" xl={6} lg={6} md={6} sm={24}>
              <a className='img-demo' href={languages[lang]?.link?.estate_2} target="_blank" rel="noreferrer">
                <img src='./estate_2.png' alt='bizverse' style={{
                  width: '100%'
                }} />
                <span>{languages[lang]?.card.estate_2}</span>
              </a>
            </Col>
            <Col className="gutter-row" xl={6} lg={6} md={6} sm={24}>
              <a className='img-demo' href={languages[lang]?.link?.estate_3} target="_blank" rel="noreferrer">
                <img src='./estate_3.png' alt='bizverse' style={{
                  width: '100%'
                }} />
                <span>{languages[lang]?.card.estate_3}</span>
              </a>
            </Col>
            <Col className="gutter-row" xl={6} lg={6} md={6} sm={24}>
              <a className='img-demo' href={languages[lang]?.link?.estate_4} target="_blank" rel="noreferrer">
                <img src='./estate_4.png' alt='bizverse' style={{
                  width: '100%'
                }} />
                <span>{languages[lang]?.card.estate_4}</span>
              </a>
            </Col>
          </Row>
          <Row className="mobile">
            <Col className="gutter-row" xl={8} lg={8} md={8} sm={24}>
              <Carousel dots className="mobile" autoplay>
                <a className='img-demo' href={languages[lang]?.link?.estate_1} target="_blank" rel="noreferrer">
                  <img src='./estate_1.png' alt='bizverse' style={{
                    width: '100%'
                  }} />
                  <span>{languages[lang]?.card.estate_1}</span>
                </a>
                <a className='img-demo' href={languages[lang]?.link?.estate_2} target="_blank" rel="noreferrer">
                  <img src='./estate_2.png' alt='bizverse' style={{
                    width: '100%'
                  }} />
                  <span>{languages[lang]?.card.estate_2}</span>
                </a>
                <a className='img-demo' href={languages[lang]?.link?.estate_3} target="_blank" rel="noreferrer">
                  <img src='./estate_3.png' alt='bizverse' style={{
                    width: '100%'
                  }} />
                  <span>{languages[lang]?.card.estate_3}</span>
                </a>
                <a className='img-demo' href={languages[lang]?.link?.estate_4} target="_blank" rel="noreferrer">
                  <img src='./estate_4.png' alt='bizverse' style={{
                    width: '100%'
                  }} />
                  <span>{languages[lang]?.card.estate_4}</span>
                </a>
              </Carousel>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center" style={{
        padding: '2em 0'
      }}
        className="room-bg"
        ref={scrollRefs.current[2]}
      >
        <Col span={20}>
          <Row style={{
            width: '100%',
            marginBottom: '1em'

          }}>
            <h1 className="title">{languages[lang].title}</h1>
            <h1 className="sub-title">{languages[lang].subtitle.title_2}</h1>
          </Row>
          <Row gutter={32}>
            <Col xl={12} lg={12} md={12} sm={12}>
              <div className='border-img'> <img src='./overview_showroom.png' alt='bizverse' style={{
                width: '100%'
              }} /></div>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12}>
              <List
                style={{
                  textAlign: 'initial'
                }}
                size="small"
                dataSource={languages[lang].showroom}
                renderItem={item => <Space
                  style={{
                    margin: '0.2em 0',
                    width: "100%",
                    alignItems: "baseline"


                  }}
                >
                  <img src='./tick.svg' alt='tick' />
                  <span >{item}</span>
                </Space>}
              />
            </Col>

          </Row>
          <Row>
            <a href='https://forms.gle/djtP8pEZobqFT27i9' target="_blank" className='register-button' rel="noreferrer" style={{
              marginBottom: 0
            }}>
              <span className='register' >{languages[lang].button}</span>
            </a>
          </Row>
          <Row>
            <span className='line-left'></span>
            <span className='demo'>DEMO</span>
            <span className='line-right'></span>
          </Row>
          <Row gutter={24} className="desktop">
            <Col className="gutter-row" xl={8} lg={8} md={8} sm={24}>
              <a className='img-demo' href={languages[lang]?.link?.room_1} target="_blank" rel="noreferrer">
                <img src='./room_1.png' alt='bizverse' style={{
                  width: '100%'
                }} />
                <span>{languages[lang]?.card.room_1}</span>
              </a>
            </Col>
            <Col className="gutter-row" xl={8} lg={8} md={8} sm={24}>
              <a className='img-demo' href={languages[lang]?.link?.room_2} target="_blank" rel="noreferrer">
                <img src='./room_2.png' alt='bizverse' style={{
                  width: '100%'
                }} />
                <span>{languages[lang]?.card.room_2}</span>
              </a>
            </Col>
            <Col className="gutter-row" xl={8} lg={8} md={8} sm={24}>
              <a className='img-demo' href={languages[lang]?.link?.room_3} target="_blank" rel="noreferrer">
                <img src='./room_3.png' alt='bizverse' style={{
                  width: '100%'
                }} />
                <span>{languages[lang]?.card.room_3}</span>
              </a>
            </Col>
          </Row>
          <Row className="mobile">
            <Col className="gutter-row" xl={8} lg={8} md={8} sm={24}>
              <Carousel dots className="mobile" autoplay>
                <a className='img-demo' href={languages[lang]?.link?.room_1} target="_blank" rel="noreferrer">
                  <img src='./room_1.png' alt='bizverse' style={{
                    width: '100%'
                  }} />
                  <span>{languages[lang]?.card.room_1}</span>
                </a>
                <a className='img-demo' href={languages[lang]?.link?.room_2} target="_blank" rel="noreferrer">
                  <img src='./room_2.png' alt='bizverse' style={{
                    width: '100%'
                  }} />
                  <span>{languages[lang]?.card.room_2}</span>
                </a>
                <a className='img-demo' href={languages[lang]?.link?.room_3} target="_blank" rel="noreferrer">
                  <img src='./room_3.png' alt='bizverse' style={{
                    width: '100%'
                  }} />
                  <span>{languages[lang]?.card.room_3}</span>
                </a>
              </Carousel>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center" style={{
        padding: '2em 0'
      }}
        ref={scrollRefs.current[3]}
        className="edu-bg"
      >
        <Col span={20}>
          <Row style={{
            width: '100%',
            marginBottom: '1em'

          }}>
            <h1 className="title">{languages[lang].title}</h1>
            <h1 className="sub-title">{languages[lang].subtitle.title_3}</h1>
          </Row>
          <Row gutter={32}>
            <Col xl={12} lg={12} md={12} sm={12}>
              <List
                style={{
                  textAlign: 'initial'
                }}
                size="small"
                dataSource={languages[lang].education}
                renderItem={item => <Space
                  style={{
                    margin: '0.2em 0',
                    width: "100%",
                    alignItems: "baseline"

                  }}
                >
                  <img src='./tick.svg' alt='tick' />
                  <span >{item}</span>
                </Space>}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12}>
              <div className='border-img'> <img src='./overview_edu.png' alt='bizverse' style={{
                width: '100%'
              }} /></div>
            </Col>
          </Row>
          <Row>
            <a href='https://forms.gle/djtP8pEZobqFT27i9' target="_blank" className='register-button' rel="noreferrer" style={{
              marginBottom: 0
            }}>
              <span className='register' >{languages[lang].button}</span>
            </a>
          </Row>
          <Row>
            <span className='line-left'></span>
            <span className='demo'>DEMO</span>
            <span className='line-right'></span>
          </Row>
          <Row gutter={24} className="desktop">
            <Col className="gutter-row" xl={8} lg={8} md={8} sm={24}>
              <a className='img-demo' href={languages[lang]?.link?.edu_1} target="_blank" rel="noreferrer">
                <img src='./edu_1.png' alt='bizverse' style={{
                  width: '100%'
                }} />
                <span>{languages[lang]?.card.edu_1}</span>
              </a>
            </Col>
            <Col className="gutter-row" xl={8} lg={8} md={8} sm={24}>
              <a className='img-demo' href={languages[lang]?.link?.edu_2} target="_blank" rel="noreferrer">
                <img src='./edu_2.png' alt='bizverse' style={{
                  width: '100%'
                }} />
                <span>{languages[lang]?.card.edu_2}</span>
              </a>
            </Col>
            <Col className="gutter-row" xl={8} lg={8} md={8} sm={24}>
              <a className='img-demo' href={languages[lang]?.link?.edu_3} target="_blank" rel="noreferrer">
                <img src='./room_3.png' alt='bizverse' style={{
                  width: '100%'
                }} />
                <span>{languages[lang]?.card.edu_3}</span>
              </a>
            </Col>
          </Row>
          <Row className="mobile">
            <Col className="gutter-row" xl={8} lg={8} md={8} sm={24}>
              <Carousel dots className="mobile" autoplay>
                <a className='img-demo' href={languages[lang]?.link?.edu_1} target="_blank" rel="noreferrer">
                  <img src='./edu_1.png' alt='bizverse' style={{
                    width: '100%'
                  }} />
                  <span>{languages[lang]?.card.edu_1}</span>
                </a>
                <a className='img-demo' href={languages[lang]?.link?.edu_2} target="_blank" rel="noreferrer">
                  <img src='./edu_2.png' alt='bizverse' style={{
                    width: '100%'
                  }} />
                  <span>{languages[lang]?.card.edu_2}</span>
                </a>
                <a className='img-demo' href={languages[lang]?.link?.edu_3} target="_blank" rel="noreferrer">
                  <img src='./room_3.png' alt='bizverse' style={{
                    width: '100%'
                  }} />
                  <span>{languages[lang]?.card.edu_3}</span>
                </a>
              </Carousel>
            </Col>
          </Row>

        </Col>
      </Row >
      <img src='./edu_mobile.png' alt='bg' className='mobile' />
      <span style={{
        marginBottom: '1em',
        marginTop: 'auto'
      }}>{languages[lang].contact}<a href='mailto:business@bizverse.world' style={{
        color: '#dc40d5',
      }}>business@bizverse.world</a>
      </span>

    </div >
  );
}

export default App;
